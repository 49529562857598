.input_wrapper {
    border: 1px solid #DDD;
    border-radius: 5px;
    padding: 0.5em;
    display: flex;
    align-items: stretch;
}

.input_wrapper:focus-within {
    border: 1px solid black;
}

.input {
    border: none;
    width:100%
}

.input:focus {
    outline: none;
}

.input::placeholder {
    color: var(--black);
    opacity: 0.2;
}