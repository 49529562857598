.pb-container {
  width: 100%;
  background: var(--dark-blue) 0% 0% no-repeat padding-box;
  height: 60px;
  margin-bottom: 20px;
}
.progressbar {
  padding: 0px 0px;
  margin-block-start: 0px !important;
  margin: 0px !important;
}
.progressbar li {
  font-weight: normal;
  list-style-type: none;
  float: left;
  position: relative;
  text-align: center;
  color: var(--white);
  font-size: 12px;
}
.progressbar li::before {
  width: 30px;
  height: 30px;
  content: attr(step-ind);
  line-height: 30px;
  border: 3px solid var(--white);
  display: block;
  text-align: center;
  margin: 5px auto 4px auto;
  border-radius: 50%;
  background-color: var(--white);
  cursor: pointer;
  color: var(--dark-blue);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressbar li::after {
  width: calc(100% - 30px);
  height: 3px;
  content: '';
  position: absolute;
  background-color: var(--white);
  top: 18px;
  left: calc(-50% + 15px);
}
.progressbar li:first-child::after {
  content: none;
}
.progressbar li.current-stage {
  font-weight: 400;
  color: var(--white);
  font-size: 12px;
}
.progressbar li.current-stage::before {
  background-color: var(--dark-blue);
  color: var(--white);
}
.progressbar li.completed-stage::before {
  border: 3px solid var(--checked);
  content: '';
  background-image: url("assets/images/v.svg");
  background-color: var(--checked);
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressbar li.completed-stage::after {
  background-color: var(--checked);
}
.progressbar li.current-stage::after {
  background-color: var(--checked);
}
.progressbar li.current-stage + li::before {
  border: 3px solid var(--white);
  cursor: pointer;
}
/* Light Theme*/
.pb-container.light {
  background: #fff 0% 0% no-repeat padding-box;
}
.progressbar.light li {
  color: var(--dark-blue);
}
.progressbar.light li.current-stage {
  color: var(--dark-blue);
}
.progressbar.light li::before {
  border: 2px solid var(--dark-blue);
}
.progressbar.light li::after {
  background-color: var(--dark-blue);
}

.progressbar.light li.current-stage + li::before {
  border: 2px solid var(--dark-blue);
}
.progressbar.light li.completed-stage::before {
  border: 3px solid var(--checked);
}
/* Size: Large */
.pb-container.large {
  height: 100px;
  padding-top: 10px;
}
.progressbar li.large::before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 35px;
}
.progressbar li.large::after {
  width: calc(100% - 50px);
  top: 28px;
  left: calc(-50% + 25px);
}
.progressbar li.large.completed-stage::before {
  background-size: 25px;
}
.progressbar li.large {
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .progressbar {
    padding: 0px 200px;
    margin-block-start: 0px !important;
    margin: 0px !important;
  }
}