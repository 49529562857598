
.machinesList {
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
    color : var(--dark-blue);
    font-size: 22pt;
    cursor: pointer;
}

.machinesList:hover{
    font-size: xx-large;
}

.removeReplace{
    width: 134px;
}

.removeReplaceChild{
    text-align: center;
    cursor: pointer;
    font-size: 12pt;
}
.enterSerialContent {
    background-color: #ffffff;
    border-radius: 10px;
    font-size: xx-large;
    text-align: center;
  }

  .promptButtons {
    margin-top: 10px;
  }

  .inputStyle {
    justify-content: center;
    border-width: 2px;
    border-radius: 10px;
    width: 100%;
  }