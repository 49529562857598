.animatedSearchSvg {
  display: flex;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin: 15px;
}
.privacyPolicyModalBody {
  height: 70vh !important;
  overflow-y: scroll !important;
  white-space: break-spaces;
}
.ssidTitle {
  font-size: 25px;
  margin: 0 !important;
}

.wifiIcon {
  height: 30px;
  width: 30px;
  vertical-align: text-bottom;
}
.passwordTitle {
  font-weight: 600;
  font-size: 14px;
}

.wifiButton {
  background-color: var(--blue) !important;
  border-radius: 35px !important;

  margin-left: 10px !important;
  margin-right: 10px !important;
}
.wifiButtonWidth200 {
  width: 200px !important;
}
.wifiButtonWidth120 {
  width: 120px !important;
}
.privacyPolicyDiv {
  &.required {
    font-weight: 700;
    color: var(--tutt-red);
  }
}

.listGroupItem {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
  font-size: 22px;
}
.connected {
  font-weight: bolder;
}
