.floating-label-group,
.floating-select-group {
  position: relative;
  margin-bottom: 1rem;
  .error-message {
    color: red;
  }

  .form-control,
  .form-select {
    padding-top: 1.5rem;
    &.error-field {
      border-color: red;
    }
  }

  .form-label {
    position: absolute;
    top: 1.25rem;
    left: 0.75rem;
    transition: all 0.2s ease-out;
    pointer-events: none;
    opacity: 1;
    z-index: 0;
  }

  .form-label.active {
    top: 0.25rem;
    font-size: 1rem;
    color: #000;
    z-index: 1;
  }

  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label {
    opacity: 1;
  }
}
