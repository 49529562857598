.level3 path:nth-of-type(1) {
  opacity: 1;
}

.level2 path:nth-of-type(2) {
  opacity: 1;
}

.level1 path:nth-of-type(3) {
  opacity: 1;
}

.level0 path:nth-of-type(4) {
  opacity: 1;
}
