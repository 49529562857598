@import 'bootstrap/dist/css/bootstrap.min.css';
@font-face {
  font-family: 'TitilliumWeb-SemiBold';
  src: local('TitilliumWeb-SemiBold'), url('./assets/fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'TitilliumWeb-Light';
  src: local('TitilliumWeb-Light'), url('./assets/fonts/TitilliumWeb-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'TitilliumWeb-Regular';
  src: local('TitilliumWeb-Regular'), url('./assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
}
body {
  font-family: 'TitilliumWeb-SemiBold';
}
:root {
  --checked: #1ec27b;
  --black: #000000;
  --tutt-red: #ee2350;
  --light-teal: #c6dfe3;
  --blue: #2681c1;
  --light-gray: #cbd3d8;
  --gray: #9697B3;
  --purple: #9696b4;
  --blue-gray: #d3d2e1;
  --dark-blue: #4c5b77;
  --white: #ffffff;
  --unnamed-color-ff009d: #ff009d;
  --unnamed-color-a2aaad: #a2aaad;
  --unnamed-color-707070: #707070;
  --unnamed-color-4c4c4c: #4c4c4c;
  --font-family-titillium-regular: 'TitilliumWeb-Regular';
}
/* fonts */
.fs-14 {
  font-size: 14px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-27 {
  font-size: 30px;
}
.fs-30 {
  font-size: 30px;
}
.underline {
  text-decoration: underline!important;
}
/* misc */
.h-70 {
  height: 70px;
}
.margin-top-70 {
  margin-top: 70px; 
}
.width-100 {
  width: 100px;
}
/* buttons */
.circle-button {
  width: 50px;
  height: 50px;
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--dark-blue);
  border-radius: 25px;
  opacity: 1;
}
.outlined-btn {
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--blue);
  border-radius: 25px;
  color: var(--blue);
  font-size: 20px;
  padding: 10px 30px;
}
.filled-btn {
  background: var(--blue) 0% 0% no-repeat padding-box;
  border-radius: 25px;
  color: var(--white);
  font-size: 20px;
  padding: 10px 30px;
}
.nb-header {
  color: var(--blue);
  font-size: 2.5em;
}
.nb-submit-button {
  background-color: var(--blue);
  color: white;
}
.nb-input-txt {
  display: block;
  width: 100%;
}
.error-input {
  border-color: var(--tutt-red);
}