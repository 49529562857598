* {
  color: var(--dark-blue);
  font-weight: normal;
  font-family: var(--font-family-titillium-regular);
}

.link {
  text-decoration: underline;
  color: 'var(--purple)';
}

@media screen and (max-width: 480px) {
  .step:before {
    content: '';
    margin-left: -100%;
  }

  .step:after {
    content: '';
    margin-right: -100%;
  }
}

@media screen and (min-width: 480px) {
  .dash {
    width: 13vw;
  }

  .circlestep {
    height: 60px;
    width: 60px;
    padding: 14px;
    border: 3px solid;
    border-radius: 50%;
    text-align: center;
  }
}

@media screen and (min-width: 1700px) {
  .dash {
    width: 8vw;
  }
}

.setup-step-title {
  color: var(--blue);
  font-size: 30px;
}

.setup-step-subtitle {
  color: var(--dark-blue);
  font-size: 20px;
}

.setup-section-title {
  color: var(--dark-blue);
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 3px;
}

.setup-section-subtitle {
  color: var(--dark-blue);
  font-size: 16px;
  margin-bottom: 10px;
}

.setup-form-field-text {
  height: 70px;
  font-size: 22px!important;
  color: var(--black)!important;
  opacity: 0.5!important;
  border-radius: 0.375rem!important;
}

.setup-comment {
  color: var(--dark-blue);
  font-size: 14px;
}

.setup-add-link {
  color: var(--dark-blue)!important;
  text-decoration: none!important;
  font-size: 20px;
}

.setup-bold-label-14 {
  font-weight: bold;
  font-size: 14px;
}

.setup-label {
  font-weight: bold;
  font-size: 14px;
  width: 50px;
}

.machine-icon {
  height: 70px;
  width: 64px;
  background-color: var(--light-gray);
  border-radius: 5px;
  margin-right: 10px;
}

.action-icon {
  height: 30px;
  width: 30px;
  background-color: var(--light-gray);
  border-radius: 15px;
  margin-left: 25px;
}

.address-marker {
  background-color: var(--blue) !important;
  margin-left: 10px !important;
  width: 70px;
  display: flex;
  justify-content: center;
  border-radius: 0.375rem !important;
}

.plus-icon {
  background-color: var(--white) !important;
  margin-left: 10px !important;
  width: 50px;
  display: flex;
  justify-content: center;
  border: 0px !important;
}
.offcanvas-backdrop.show { 
  opacity: .0 !important; 
}