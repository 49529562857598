.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: calc(100vh - 62px);
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
